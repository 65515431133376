import {redirect, type LoaderArgs, defer} from '@shopify/remix-oxygen';
import type {Product as ShopifyProduct} from '@shopify/hydrogen/storefront-api-types';

import {
  ApprovalStatus,
  Collection,
  useGetCollectionForPortalQuery,
  useGetCollectionQuery,
  useListCollectionsQuery,
  Product,
  useGetBrandProductsForConsumerQuery,
  useUpsertCollectionMutation,
  useListCollectionsWithProductsQuery,
} from 'app/gql/generated';
import {QueryClient} from '@tanstack/react-query';
import {Storefront} from '~/lib/type';
import {getLoaderExpertName, getPageStartAndEnd} from '~/modules/Common/util';

import {getAllProducts} from './allProductsLoader';
import {mapProductValidShopifyIds} from '../utils/productUtils';
import {invalidCreatorNames} from '~/modules/Expert/util';
import {getExpert} from '~/modules/Expert/utils.server';
import {
  getShopifyProductsByIds,
  getWaywardAndShopifyProductsByIds,
} from '../utils/product.utils.server';
import {getPaginatedPosts} from './postsLoader';

export const getLoader = (needsApproval: boolean) =>
  async function loader({
    context: {storefront, client},
    params,
    request,
  }: LoaderArgs) {
    const expertName = getLoaderExpertName(params, request);
    if (
      !expertName ||
      invalidCreatorNames.includes(expertName) ||
      expertName.endsWith('.php')
    ) {
      return redirect('/404');
    }

    if (expertName === 'newbrand') {
      return redirect('/brand-user/login');
    }

    if (expertName === 'demostore') {
      return redirect('/expert/login');
    }

    const expert = await getExpert(client, expertName);

    if (
      !expert?.id ||
      (needsApproval && expert.storeApprovalStatus !== ApprovalStatus.Approved)
    ) {
      return redirect('/404');
    }
    const theme = JSON.parse(expert?.theme || '');
    const storeTheme = typeof theme === 'string' ? JSON.parse(theme) : theme;
    const defaultDescription =
      'Shop my personal top picks - exclusive discounts already applied!';

    const {promises, allCollection, maxCollections} =
      await getExpertCollectionsAndProducts(expertName, storefront, {
        client,
        filterVisible: true,
        page: 1,
        limit: 3,
      });

    return defer({
      expertName,
      storeName: storeTheme?.v2?.title || storeTheme?.store?.name || '',
      posts: getPaginatedPosts(expertName, {
        client,
        filterVisible: true,
        page: 1,
      }),
      description:
        storeTheme?.v2?.subtext ||
        (storeTheme?.store?.isDefault && defaultDescription) ||
        storeTheme?.store?.subtitleText ||
        defaultDescription,
      collectionCount: promises.length,
      maxCollections: maxCollections || 0,
      ...promises.reduce((acc, item, index) => {
        acc[`collection-${index}`] = item;
        return acc;
      }, {}),
      // logic below is the instance that the user has no collections, so we need to fetch all products on the server
      allProducts: !promises.length
        ? getAllProducts(expertName, storefront, {
            client,
            filterVisible: true,
            page: 1,
            limit: 20,
          })
        : null,
      allCollection: !promises.length ? allCollection : undefined,
    });
  };

/**
 * Returns an array of promises that resolve to the collections and products
 */
interface GetExpertOptions {
  client?: QueryClient;
  filterVisible?: boolean;
  page: number;
  limit: number;
}

export async function getExpertCollectionsAndProducts(
  expertName: string,
  storefront: Storefront,
  options: GetExpertOptions = {
    filterVisible: true,
    page: 1,
    limit: 3,
  },
) {
  const client = options?.client || new QueryClient();
  const {start, end} = getPageStartAndEnd(options.page, options.limit);

  try {
    const collectionsResp = await getApiExpertCollections(client, expertName);

    const visibleCollections = filterVisibleCollections(
      collectionsResp || [],
      options.filterVisible,
    );
    const maxCollections = visibleCollections.length;
    const collectionPromises: Promise<{
      collection: Collection;
      shopifyProducts: {nodes: ShopifyProduct[]};
      apiProducts: Product[];
    }>[] = visibleCollections
      .slice(start, end)
      .map((collection) =>
        loadCollection(collection, storefront, expertName, client),
      );

    return {
      promises: collectionPromises,
      allCollection: collectionsResp.find(({id}) => id === 'ALL_PRODUCTS'),
      maxCollections,
    };
  } catch (e) {
    console.error(e);
    return {
      promises: [],
      allCollection: undefined,
      maxCollections: 0,
    };
  }
}

function filterVisibleCollections(
  collections: Collection[],
  filterVisible: boolean,
) {
  return collections.filter(
    (collection) =>
      filterVisible &&
      collection.displayInHomePage &&
      collection.productNum &&
      collection.id !== 'ALL_PRODUCTS',
  );
}

async function loadCollection(
  initialCollection: Collection,
  storefront: Storefront,
  expertName: string,
  client: QueryClient,
) {
  const {collection, shopifyProducts, products} = await getExpertCollection(
    initialCollection.id,
    expertName,
    storefront,
    {
      client,
      filterVisible: true,
      page: 1,
      limit: 10,
    },
  );

  return {
    collection,
    shopifyProducts,
    apiProducts: products,
  };
}

export async function getApiExpertCollections(
  client: QueryClient,
  expertName: string,
  withProductIds = false,
) {
  try {
    const all = withProductIds
      ? await client.fetchQuery(
          useListCollectionsWithProductsQuery.getKey({expertName}),
          useListCollectionsWithProductsQuery.fetcher({expertName}),
        )
      : await client.fetchQuery(
          useListCollectionsQuery.getKey({expertName}),
          useListCollectionsQuery.fetcher({expertName}),
        );
    return all.listCollections || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getExpertCollection(
  collectionId: string,
  creatorName: string,
  storefront: Storefront,
  options?: {
    client?: QueryClient;
    internal?: boolean;
    filterVisible?: boolean;
    isPreview?: boolean;
    page?: number;
    limit?: number;
  },
) {
  const client = options?.client || new QueryClient();

  try {
    const resp = options?.internal
      ? await client.fetchQuery(
          useGetCollectionForPortalQuery.getKey({collectionId}),
          useGetCollectionForPortalQuery.fetcher({collectionId}),
        )
      : await client.fetchQuery(
          useGetCollectionQuery.getKey({collectionId}),
          useGetCollectionQuery.fetcher({collectionId}),
        );
    const collection = resp.getCollection;

    const {products, shopifyProducts} = await getWaywardAndShopifyProductsByIds(
      collection.productIds || [],
      creatorName,
      client,
      storefront,
      {
        page: options?.page,
        limit: options?.limit,
        filterVisible: options?.filterVisible,
        internal: options?.internal,
        isPreview: options?.isPreview,
      },
    );

    return {
      collection,
      shopifyProducts,
      products,
    };
  } catch (e) {
    return {
      collection: null,
      shopifyProducts: {
        nodes: [],
      },
      products: [],
    };
  }
}

export async function getPaginatedCollection(
  collectionId: string,
  expertName: string,
  storefront: Storefront,
  options: {
    client: QueryClient;
    filterVisible?: boolean;
    page: number;
    limit: number;
  },
) {
  try {
    const {collection, products, shopifyProducts} = await getExpertCollection(
      collectionId,
      expertName,
      storefront,
      {
        client: options.client,
        filterVisible: options.filterVisible,
        page: options.page,
        limit: options.limit,
      },
    );

    return {
      collection,
      products,
      shopifyProducts: shopifyProducts as {
        nodes: ShopifyProduct[];
      },
    };
  } catch (e) {
    console.error(e);
    return {
      collection: null,
      products: [],
      shopifyProducts: {
        nodes: [],
      },
    };
  }
}

export async function getPaginatedCollectionsById(
  collectionIds: string[],
  expertName: string,
  storefront: Storefront,
  options: {
    client: QueryClient;
    filterVisible?: boolean;
    page: number;
    limit: number;
  },
): Promise<{
  collections: Collection[];
  shopifyProducts: ShopifyProduct[];
}> {
  const allCollections = await Promise.allSettled(
    collectionIds.map((collectionId) =>
      getPaginatedCollection(collectionId, expertName, storefront, options),
    ),
  );
  const validValues = allCollections
    .map((r) => r.value)
    .filter((r) => r?.collection);
  const collections = validValues.map((r) => r.collection);
  const shopifyProducts = validValues
    .map((r) => r.shopifyProducts.nodes)
    .flat();

  return {
    collections,
    shopifyProducts,
  };
}

export async function getExpertBrandCollection(
  creatorName: string,
  brandSlug: string,
  storefront: Storefront,
  options?: {
    client?: QueryClient;
  },
) {
  const client = options?.client || new QueryClient();

  let products = [];
  let shopifyProducts = {nodes: []};
  let collection = null;
  try {
    const resp = await client.fetchQuery(
      useGetBrandProductsForConsumerQuery.getKey({creatorName, brandSlug}),
      useGetBrandProductsForConsumerQuery.fetcher({creatorName, brandSlug}),
    );
    collection = resp.getBrandProductsForConsumer;
    products = collection.products;

    shopifyProducts = await getShopifyProductsByIds(
      mapProductValidShopifyIds(products),
      storefront,
    );
  } catch (e) {
    console.error(e);
  }

  return {
    collection: {
      id: '',
      slug: collection?.brandSlug || '',
      name: collection?.brandName || '',
      description: collection?.brandDescription || '',
      products,
    },
    shopifyProducts: shopifyProducts as {
      nodes: ShopifyProduct[];
    },
  };
}

const getBaseCollection = async (collectionId: string, client: QueryClient) => {
  try {
    const res = await client.fetchQuery(
      useGetCollectionForPortalQuery.getKey({collectionId}),
      useGetCollectionForPortalQuery.fetcher({collectionId}),
    );

    return res.getCollection || null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const addProductToCollection = async (
  listCollection: Collection,
  productIds: string[],
  client: QueryClient,
) => {
  const collection = await getBaseCollection(listCollection.id, client);
  if (!collection) {
    return null;
  }
  return updateCollection(
    collection,
    Array.from(new Set([...productIds, ...collection.productIds])),
  );
};

export const removeProductFromCollection = async (
  listCollection: Collection,
  productIds: string[],
  client: QueryClient,
) => {
  const collection = await getBaseCollection(listCollection.id, client);
  if (!collection) {
    return null;
  }
  const nextIds = Array.from(
    new Set(collection.productIds || []).filter(
      (id) => !productIds.includes(id),
    ),
  );
  return updateCollection(collection, nextIds);
};

const updateCollection = async (
  collection: Collection,
  productIds: string[],
) => {
  const payload = {
    collectionId: collection.id,
    creatorId: collection.creatorId,
    name: collection.name,
    description: collection.description,
    displayInNavigation: collection.displayInNavigation,
    displayInHomePage: collection.displayInHomePage,
    bannerImageUrl: collection.bannerImageUrl,
    productIds,
  };
  return useUpsertCollectionMutation.fetcher({
    upsertCollectionInput: payload,
  })();
};
